<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(item, index) in list"
        :key="'hot_'+index"
        cols="4"
        sm="3"
        lg="2"
        class="pa-1 pa-sm-2"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            flat
            color="box_bg"
          >
            <v-img
              :src="item.logo"
              :aspect-ratio="1/1"
              @click="gotogame(item)"
            >
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  opacity="0.6"
                  class="text-center"
                >
                  <v-btn
                    small
                    fab
                    color="primary"
                  >
                    <v-icon>mdi-play</v-icon>
                  </v-btn>
                  <div>Play</div>
                </v-overlay>
              </v-fade-transition>
            </v-img>
            <v-card-title
              class="text-caption pa-2 d-flex justify-space-between"
            >
              <span
                class="text-truncate"
                style="width: 80%; "
              >
                {{ item.name }}
              </span>
              <v-icon
                small
                :class="item.fav ? 'green--text' : ''"
                icon
                @click="fav(item)"
              >
                mdi-star
              </v-icon>
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>

      <v-col
        cols="12"
        class="text-center"
      >
        <v-btn
          v-if="(page-1)<pagecount"
          rounded
          outlined
          color="primary"
          class="mb-3"
          @click="home_hot"
        >
          {{ $t('jzgd') }} ({{ remaining }})
        </v-btn>
        <small
          v-if="(page-1)>=pagecount"
          class="text--disabled"
        >{{ $t('yqbjzwb') }}</small>
      </v-col>
    </v-row>
    <template v-if="showdialog">
      <Login
        v-model="loginreg"
        @closeloginreg="closeloginreg"
        @showforget="showforget"
      />
    </template>
    <template v-if="forget">
      <Forget
        @closeforget="closeforget"
      />
    </template>
  </v-container>
</template>
<script>
import Login from '@/components/Login.vue'
import Forget from '@/components/Forget.vue'
export default {
  name: 'HomeHot',
  components:{
    Login,
    Forget
  },
  props: {
    orderby: {
      type: String,
      default: '',
      required: false
    }
  },
  data: () => ({
    showdialog: false,
    loginreg: false,
    forget: false,
    player_id: 0,
    list: [],
    page: 1,
    pagecount: 1,
    remaining: 0,
  }),
  watch:{
    orderby: {
      handler(newVal, oldVal) {
        this.list = []
        this.page = 1
        this.pagecount = 1
        this.remaining = 0
        this.home_hot()
      }
    }
  },
  created() {
    if(this.getStorage('player_id')){
      this.player_id = this.getStorage('player_id')
    }
  },
  mounted() {
    this.home_hot()
  },
  methods: {
    gotogame(item) {
      if(this.player_id>0){
        if(item.maintain==1){
          this.$snackbar.warning('maintenance')
        }else{
          this.$router.push('/game/'+item.id)
        }
      }else{
        this.showloginreg('login')
      }
    },
    showloginreg(tab) {
      this.loginreg = tab
      this.showdialog = true
    },
    closeloginreg() {
      this.loginreg = false
      this.showdialog = false
    },
    showforget() {
      this.forget = true
    },
    closeforget() {
      this.forget = false
    },
    home_hot() {
      if(this.page>this.pagecount){
        return false
      }
      let paramObj = {
        player_id: this.getStorage('player_id'),
        page:this.page,
        orderby:this.orderby
      }
      this.$server.home_hot(paramObj).then((response) => {
        if(response.code==200){
          this.pagecount = response.data.last_page
          if(response.data.data.length>0){
            this.list.push(...response.data.data)
          }
          ++this.page
          this.remaining = response.data.total - response.data.current_page*response.data.per_page
        }else{
          this.$snackbar.error(response.msg)
        }
      })
    },
    fav(item) {
      if(this.getStorage('player_id')<1){
        return false
      }
      if(item.fav){
        this.favcancel(item.id)
      }else{
        this.favadd(item.id)
      }
      item.fav = !item.fav
    },
    favadd(game_id) {
      let paramObj = {
        player_id: this.getStorage('player_id'),
        game_id:game_id
      }
      this.$server.favadd(paramObj).then((response) => {
        if(response.code==200){
          
        }else{
          
        }
      })
    },
    favcancel(game_id) {
      let paramObj = {
        player_id: this.getStorage('player_id'),
        game_id:game_id
      }
      this.$server.favcancel(paramObj).then((response) => {
        if(response.code==200){
          
        }else{
          
        }
      })
    },
  }
};
</script>