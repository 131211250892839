<template>
  <v-container>
    <v-subheader class="mb-3">
      <v-btn
        icon
        small
        to="/"
      >
        <v-icon small>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <strong class="ml-2">{{ $t('rmyx') }}</strong>
      <v-spacer />
      <div>
        <v-select
          v-model="orderby"
          solo
          flat
          background-color="box_bg"
          dense
          :items="orderbyitems"
          :label="$t('paixu')"
          no-data-text=""
          hide-details
          style="width: 6rem;"
        />
      </div>
    </v-subheader>
    <HomeHot :orderby="orderby" />
    <BackTop />
  </v-container>
</template>

<script>
import BackTop from '@/components/BackTop.vue'
import HomeHot from '@/views/pages/Home_hot.vue'
export default {
  components: {
    BackTop,
    HomeHot
  },
  data: () => ({
    orderbyitems: [
      {'text': 'Default', 'value': ''},
      {'text': 'A-Z', 'value': 'az'},
      {'text': 'Z-A', 'value': 'za'},
      {'text': 'New', 'value': 'new'}
    ],
    orderby: '',
  }),
  watch:{
    orderby: {
      handler(newVal, oldVal) {
        
      }
    }
  },
}
</script>