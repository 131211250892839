<template>
  <v-btn
    v-show="backTopShow"
    fab
    fixed
    right
    dark
    small
    class="mx-2"
    color="purple"
    @click="$vuetify.goTo(0,{
      duration: 500,
      offset: 0,
      easing: 'easeInOutCubic',
    })"
  >
    <v-icon>mdi-chevron-up</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'BackTop',
    data: () => ({
      backTopShow: false
    }),
    computed: {

    },
    watch: {

    },
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
    },
    created() {

    },
    methods:{
      
      handleScroll() {
        let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
        if (scrollTop > 200) {
          this.backTopShow = true;
        } else {
          this.backTopShow = false;
        }
      }
      
    }
  }
</script>
<style scoped>
  .v-btn--fab {
    bottom: 8%;
  }
</style>