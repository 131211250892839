<template>
  <v-dialog
    v-model="showdialog"
    persistent
    width="500"
  >
    <v-card color="app_bg">
      <v-toolbar
        flat
        color="nav_bg"
      >
        <v-toolbar-title>{{ $t('czmm') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="closeforget"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      
      <v-card-title class="text-body-1 font-weight-regular">
        <v-spacer />{{ currentTitle }}<v-spacer />
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text class="pt-0">
            <v-text-field
              v-model.number="phone"
              type="number"
              dense
              solo
              flat
              background-color="btn_bg"
              prefix="+55"
              prepend-inner-icon="mdi-cellphone"
              :placeholder="$t('qsrsjh')"
            />

            <v-row
              no-gutters
              align-center
            >
              <v-col cols="7">
                <v-text-field
                  v-model.number="code"
                  type="number"
                  dense
                  solo
                  background-color="btn_bg"
                  flat
                  prepend-inner-icon="mdi-message-badge-outline"
                  :placeholder="$t('qsryzm')"
                />
              </v-col>

              <v-col
                cols="5"
                class="d-flex align-center justify-center"
                style="height: 38px;"
              >
                <v-btn
                  outlined
                  color="primary"
                  :disabled="codebtndisabled"
                  @click="sendsms"
                >
                  {{ codebtntext }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text>
            <v-text-field
              v-model="password"
              dense
              flat
              solo
              background-color="btn_bg"
              prepend-inner-icon="mdi-form-textbox-password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              :placeholder="$t('szmm')"
              @click:append="show1 = !show1"
            />
            <v-text-field
              v-model="repassword"
              dense
              flat
              solo
              background-color="btn_bg"
              prepend-inner-icon="mdi-form-textbox-password"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              :placeholder="$t('qrmm')"
              @click:append="show2 = !show2"
            />
          </v-card-text>
        </v-window-item>

        <v-window-item :value="3">
          <div class="pa-8 text-center">
            <v-avatar
              color="primary"
              size="62"
            >
              <v-icon
                large
                dark
              >
                mdi-check
              </v-icon>
            </v-avatar>

            <h3 class="text-h6 font-weight-light mt-3">
              {{ $t('mmszcg') }}
            </h3>
          </div>
        </v-window-item>
      </v-window>

      <v-divider />

      <v-card-actions>
        <!--
        <v-btn
          v-if="step>1 && step<3"
          text
          @click="step--"
        >
          <<
        </v-btn>
        -->
        <v-spacer />
        <v-btn
          v-if="step<3"
          color="primary"
          depressed
          @click="nextstep"
        >
          {{ $t('next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'Forget',
    props: {

    },
    data () {
      return {
        showdialog: true,
        step: 1,
        show1: false,
        show2: false,
        countdown1: 60,
        timer1: null,
        codebtntext: '',
        codebtndisabled: false,
        phone: '',
        code: '',
        password: '',
        repassword: ''
      }
    },
    computed: {
      currentTitle () {
        switch (this.step) {
          case 1: return this.$t('yzsjh')
          case 2: return this.$t('szxmm')
          default: return this.$t('czcg')
        }
      },
    },
    created() {
      this.codebtntext = this.$t('hqyzm')
    },
    methods: {
      nextstep() {
        if(this.step==1){
          this.verifycode()
        }
        if(this.step==2){
          this.resetpwd()
        }
      },
      sendsms() {
        var phone_regExp = /^\d{6,13}$/
        if (!phone_regExp.test(this.phone)) {
          this.$snackbar.warning(this.$t('sjhmgscw'))
          return false;
        }
        this.codebtndisabled = true
        let paramObj = {
          phone: this.phone
        }
        this.$server.sendsms(paramObj).then((response) => {
          if(response.code==200){
            this.timer1 = setInterval(() => {
              if (this.countdown1 > 0 && this.countdown1 <= 60) {
                this.countdown1--;
                if (this.countdown1 !== 0) {
                  this.codebtntext = this.$t('hqyzm') + " (" + this.countdown1 + ")"
                } else {
                  this.clear()
                }
              }
            }, 1000)
          }else{
            this.codebtndisabled = false
            this.$snackbar.warning(this.$t('fssb'))
          }
        })
      },
      clear() {
        clearInterval(this.timer1)
        this.countdown1 = 60
        this.timer1 = null
        this.codebtndisabled = false
        this.codebtntext = this.$t('hqyzm')
      },
      verifycode() {
        var phone_regExp = /^\d{6,13}$/
        if (!phone_regExp.test(this.phone)) {
          this.$snackbar.warning(this.$t('sjhmgscw'))
          return false;
        }
        let paramObj = {
          phone: this.phone,
          code: this.code
        }
        this.$server.verifycode(paramObj).then((response) => {
          if(response.code==200){
            this.step++
          }else{
            this.$snackbar.warning(this.$t('yzsb'))
          }
        })
      },
      resetpwd() {
        if (this.password == '') {
          this.$snackbar.warning(this.$t('qsrxmm'))
          return false;
        }
        if (this.password != this.repassword){
          this.$snackbar.warning(this.$t('mmbyz'))
          return false;
        }
        let paramObj = {
          phone: this.phone,
          code: this.code,
          password: this.password,
          repassword: this.repassword
        }
        this.$server.resetpwd(paramObj).then((response) => {
          if(response.code==200){
            this.step++
          }else{
            this.$snackbar.warning(this.$t('czmmsb'))
          }
        })
      },
      closeforget() {
        this.showdialog = false
        this.$emit('closeforget')
      }
    }
  }
</script>